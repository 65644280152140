import Solcast from "solcast-unofficial";

const solcastApi = process.env.VUE_APP_SOLCAST_API_KEY;

const get = (long, lat) => {
  if (!long || !lat) return;
  // console.log("solcastApi", solcastApi);

  var solcast = new Solcast(solcastApi, 1);
  return solcast
    .getRADForcasts(long, lat)
    .then(result => {
      // Log results
      console.log(result);
    })
    .catch(err => {
      console.log("Erro com solcast", err);
    });

  // Installation capacity in watts (e.g. 5 kW system)
  // var capacity = 5000;

  // Gets PV generation forecast
  // solcast
  //   .getPVEstimatedActuals(long, lat, capacity)
  //   .then(result => {
  //     // Log results
  //     console.log(result);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
};
export default get;
