<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="500px">
      <v-card>
        <v-card-title
          >{{ $t("select-model") }} {{ $t(type).toLowerCase() }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-select
            v-model="selectedBrand"
            :items="brands"
            :label="$t('brand')"
            outlined
            @change="updateBrand"
          ></v-select>
          <v-radio-group v-if="selectedBrand" v-model="selectedModel" column>
            <v-radio
              v-for="(device, key) in brandModelList"
              :key="key"
              :label="device.model"
              :value="device"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary darken-1" text @click="$emit('close')">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="secondary darken-1"
            text
            @click="$emit('add', selectedModel)"
            >{{ $t("add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    type: String,
    dialog: Boolean,
    devicesData: Array
  },
  data() {
    return {
      selectedModel: null,
      selectedBrand: null
    };
  },
  computed: {
    brands() {
      if (this.devicesData) return this.devicesData.map(i => i.brand);
      return [];
    },
    brandModelList() {
      return this.devicesData
        .filter(i => i.brand === this.selectedBrand)
        .sort();
    },
    show: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    updateBrand() {
      this.selectedModel = null;
    }
  }
};
</script>
