<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
        <v-card-title>{{ $t("select-language") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <div
            @click="$emit('changeLanguage', lang.locale)"
            v-for="(lang, key) in langs"
            :key="key"
            :class="
              (selectedLang.locale === lang.locale ? 'selected' : '') +
                ' d-flex flex-row justify-space-between my-4'
            "
          >
            <div class="d-flex flex-row">
              <span>{{ lang.name }}</span>
            </div>
            <div class="text-right">
              <gb-flag
                :code="lang.flag"
                :size="selectedLang.locale === lang.locale ? 'medium' : 'small'"
              />
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary darken-1" text @click="$emit('close')">{{
            $t("ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    langs: Array,
    selectedLang: Object
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>
<style scoped>
.selected {
  padding: 20px 15px;
  background: rgba(255, 255, 255, 0.3);
}
</style>
