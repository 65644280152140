<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="800px">
      <v-card class="py-4">
        <v-card-subtitle>{{ $t("share-help") }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text style="height: 100px;">
          <v-text-field style="font-size: 1em;" :value="url"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="$emit('close')">{{ $t("ok") }}</v-btn>
          <v-btn
            :color="copyText === 'copy' ? 'pink' : 'purple'"
            text
            @click="copy"
            >{{ $t(copyText) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    url: String
  },
  data() {
    return {
      copyText: "copy"
    };
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async copy() {
      navigator.clipboard
        .writeText(this.url)
        .then(() => {
          this.copyText = "copied";
        })
        .catch(err => {
          // This can happen if the user denies clipboard permissions:
          console.error("Could not copy text: ", err);
        });
    }
  }
};
</script>
