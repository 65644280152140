<template>
  <div>
    <v-stepper v-model="stepper">
      <v-stepper-header class="d-print-none">
        <v-stepper-step :complete="stepper > 1" step="1">
          {{ $t("choose-devices") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 2" step="2">{{
          $t("solar-settings")
        }}</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 3" step="3">{{
          $t("battery-info")
        }}</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">{{ $t("result") }}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <Devices
            :devicesData="devicesData"
            @addDevice="addDevice"
            @removeDevice="removeDevice"
            :deviceTotalConsume="deviceTotalConsume"
            :addedDevices="addedDevices"
            :hasSelected="hasSelectedDevice"
            :deviceOptions="deviceOptions"
          />
          <v-btn
            class="right"
            :disabled="!hasSelectedDevice"
            color="primary"
            @click="changeStep2"
            >{{ $t("continue") }}</v-btn
          >
        </v-stepper-content>

        <v-stepper-content step="2">
          <Radiation
            @updateSunTime="updateSunTime"
            @updateLocation="updateLocation"
            :currLocation="currLocation"
            :sunTime="sunTime"
            :deviceTotalConsume="deviceTotalConsume"
          />
          <div class="right">
            <v-btn class="mr-4" @click="stepper = stepper - 1" text>{{
              $t("go-back")
            }}</v-btn>
            <v-btn
              :disabled="!currLocation"
              color="primary"
              @click="changeStep3"
            >
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <Battery
            :dischargeModel="dischargeModel"
            :loss="loss"
            :autonomy="autonomy"
            :selectedBatteryBankOutput="selectedBatteryBankOutput"
            :batteryTypes="batteryTypes"
            @updateDischarge="updateDischarge"
            @updateLoss="updateLoss"
            @updateAutonomy="updateAutonomy"
            @updateBatteryType="updateBatteryType"
          />
          <div class="right">
            <v-btn class="mr-4" @click="stepper = stepper - 1" text>{{
              $t("go-back")
            }}</v-btn>
            <v-btn color="primary" @click="changeStep4">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="4">
          <Result
            :addedDevices="addedDevices"
            :deviceTotalConsume="deviceTotalConsume"
            :sunTime="sunTime"
            :maxLoad="maxLoad"
            :dischargeModel="dischargeModel"
            :loss="loss"
            :autonomy="autonomy"
            :selectedBatteryBankOutput="selectedBatteryBankOutput"
            :batteryTypes="batteryTypes"
            :currLocation="currLocation"
          />
          <div class="right">
            <v-btn
              class="d-print-none mr-4"
              @click="stepper = stepper - 1"
              text
              >{{ $t("go-back") }}</v-btn
            >
            <v-btn class="d-print-none" color="secondary" @click="restart">{{
              $t("restart")
            }}</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import Devices from "./Devices";
import Radiation from "./Radiation";
import Battery from "./Battery";
import Result from "./Result";
import { generateId, parseId } from "@/lib/parseId.js";

const localStorageKey = "@Coolab/solar-calculator@v1";

export default {
  name: "SolarCalculator",
  components: {
    Devices,
    Radiation,
    Battery,
    Result
  },
  data() {
    return {
      devicesData: [],
      stepper: 1,
      addedDevices: [],
      deviceOptions: [
        {
          name: "router",
          icon: require("@/assets/router.png")
        },
        {
          name: "computer",
          icon: require("@/assets/pi.png")
        },
        // {
        //   name: "micro-controller",
        //   icon: require("@/assets/micro-controller.png")
        // },
        // {
        //   name: "other",
        //   icon: require("@/assets/micro-controller.png")
        // },
        {
          name: "add-device",
          icon: require("@/assets/micro-controller.png")
        }
      ],
      currLocation: [],
      sunTime: 6,
      dischargeModel: 50,
      loss: 10,
      autonomy: 24,
      selectedBatteryBankOutput: 12
    };
  },
  async mounted() {
    if (window) {
      try {
        const localData = window.localStorage.getItem(localStorageKey);
        this.devicesData = JSON.parse(localData);
      } catch {
        console.log("First load");
        this.devicesData = [];
      }
    }
    this.parseUrl();
    try {
      const newData = await this.fetchData();
      const dataIsDifferent =
        JSON.stringify(newData) !== JSON.stringify(this.devicesData);
      if (dataIsDifferent && newData.length > 0) {
        console.log("Got new data!");
        this.devicesData = newData;
      } else {
        console.log("No new data");
      }
    } catch (err) {
      console.log("Failed to fetch Spreadsheet:", err);
    }
  },
  methods: {
    async fetchData() {
      const response = await fetch(
        `https://opensheet.elk.sh/${process.env.VUE_APP_DOCS_ID}/1`
      );
      const data = await response.json();
      let finalData = data.map(i => {
        let newObj = {};
        Object.entries(i).map(([key, value]) => {
          newObj[key.toLowerCase()] = value;
        });
        // Check lib/parseId.js - takes model and brand
        const { model, brand, name, amps, volts } = newObj;
        newObj.id = generateId(model, brand);
        newObj.name = name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
        newObj.powerSupply = {
          volts: parseFloat(volts),
          amps: parseFloat(amps)
        };
        return newObj;
      });
      window.localStorage.setItem(localStorageKey, JSON.stringify(finalData));
      return finalData;
    },
    addDevice(device) {
      let icon = this.deviceOptions.filter(d => d.name === "add-device")[0]
        .icon;
      this.deviceOptions.forEach(d => {
        if (d.name === device.type) return d.icon;
      });
      if (device.type === "other") {
        device.id = `${device.model}~${device.powerSupply.volts}~${device.powerSupply.amps}`;
      }
      this.addedDevices = this.addedDevices.concat({
        ...device,
        icon
      });
    },
    removeDevice(id) {
      this.addedDevices = this.addedDevices.filter(d => d.id !== id);
    },
    parseUrl() {
      let params = [];
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      urlParams.forEach((value, key) =>
        params.push({
          name: key,
          value
        })
      );
      params.forEach(p => {
        switch (p.name) {
          case "devices":
            p.value.split(",").forEach(d => {
              let device;
              if (d.split("~").length === 3) {
                const [model, volts, amps] = d.split("~");
                device = {
                  model,
                  powerSupply: {
                    amps: parseFloat(amps),
                    volts: parseFloat(volts)
                  },
                  type: "other"
                };
              } else {
                device = parseId(this.devicesData, d);
              }
              this.addDevice(device);
            });
            break;
          case "sun":
            this.sunTime = parseInt(p.value);
            break;
          case "dm":
            this.dischargeModel = parseInt(p.value);
            break;
          case "lo":
            this.loss = parseInt(p.value);
            break;
          case "au":
            this.autonomy = parseInt(p.value);
            break;
          case "bt":
            this.selectedBatteryBankOutput = parseInt(p.value);
            break;
          default:
            break;
        }
      });
      if (params.length === 1) this.stepper = 2;
      else if (params.length === 6) this.stepper = 4;
      else if (params.length > 1) this.stepper = 3;
      return params;
    },
    changeStep2() {
      this.stepper = 2;
      this.$router.push({
        path: "/",
        query: {
          devices: this.addedDevices.map(i => i.id).join(",")
        }
      });
    },
    updateSunTime(n) {
      this.sunTime = n;
    },
    updateLocation(loc) {
      this.currLocation = loc;
    },
    changeStep3() {
      this.stepper = 3;
      this.$router.push({
        path: "/",
        query: {
          ...this.$route.query,
          sun: this.sunTime
        }
      });
    },
    updateDischarge(n) {
      this.dischargeModel = n;
    },
    updateLoss(n) {
      this.loss = n;
    },
    updateAutonomy(n) {
      this.autonomy = n;
    },
    updateBatteryType(t) {
      this.selectedBatteryBankOutput = t;
    },
    changeStep4() {
      this.stepper = 4;
      this.$router.push({
        path: "/",
        query: {
          ...this.$route.query,
          dm: this.dischargeModel,
          lo: this.loss,
          au: this.autonomy,
          bt: this.selectedBatteryBankOutput
        }
      });
    },
    restart() {
      this.stepper = 1;
      this.addedDevices = [];
      this.sunTime = 6;
      this.dischargeModel = 50;
      this.loss = 10;
      this.autonomy = 24;
      this.selectedBatteryBankOutput = 12;
      this.$router.push("/");
    }
  },
  computed: {
    hasSelectedDevice() {
      return this.addedDevices.length > 0;
    },
    maxLoad() {
      return this.addedDevices.reduce((prev, curr) => {
        if (curr.powerSupply.volts > prev) return curr.powerSupply.volts;
        else return prev;
      }, 0);
    },

    batteryTypes() {
      // TODO: deveria dar opção de saída baseado no sistema?
      // if (this.maxLoad / 12 > 1) return [24, 12];
      return [12, 24];
    },
    deviceTotalConsume() {
      return this.addedDevices.reduce((prev, curr) => {
        const total = curr.powerSupply.volts * curr.powerSupply.amps;
        return Math.round((prev + total) * 10) / 10;
      }, 0);
    }
  }
};
</script>
<style scoped>
.right {
  float: right;
}
</style>
