<template>
  <div>
    <v-main class="container d-print-none">
      <p class="body-1 text-justify mx-auto max-width mb-8">
        {{ $t("result-text") }}
      </p>
      <v-row justify="center" class="mx-auto max-width">
        <v-col md="4" xs="12">
          <v-card class="py-4 px-4" elevation="8" color="blue-grey darken-2">
            <v-card-title>
              <span class="title">{{ $t("panel") }}</span>
            </v-card-title>
            <v-card-text>
              <v-img
                :src="require('@/assets/panel.png')"
                alt="panel"
                class="shrink mr-2 mt-4"
                contain
                transition="scale-transition"
                width="100"
                max-height="100"
              />
            </v-card-text>
            <v-card-subtitle class="headline">
              {{ panelPower }} Wp
              <v-tooltip bottom max-width="30vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-4">
                    <v-icon light> mdi-help </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("help-wp") }}</span>
              </v-tooltip>
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="4" xs="12">
          <v-card class="py-4 px-4" elevation="8" color="blue-grey darken-3">
            <v-card-title>
              <span class="title">{{ $t("battery") }}</span>
            </v-card-title>
            <v-card-text>
              <v-img
                :src="require('@/assets/battery.png')"
                alt="panel"
                class="shrink mr-2 mt-4"
                contain
                transition="scale-transition"
                width="100"
                max-height="100"
              />
            </v-card-text>
            <v-card-subtitle class="headline">
              <span v-if="numberOfBatteries > 1">
                {{ numberOfBatteries }}
                <v-icon class="px-1" size="16px">mdi-close</v-icon>
              </span>
              {{ commercialBatteryAh }} Ah
              <v-tooltip bottom max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-4">
                    <v-icon light> mdi-help </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("help-ah") }}</span>
              </v-tooltip>
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="4" xs="12">
          <v-card class="py-4 px-4" elevation="8" color="blue-grey darken-2">
            <v-card-title>
              <span class="title">{{ $t("charge-controller") }}</span>
            </v-card-title>
            <v-card-text>
              <v-img
                :src="require('@/assets/controller.png')"
                alt="panel"
                class="shrink mr-2 mt-4"
                contain
                transition="scale-transition"
                width="100"
                max-height="100"
              />
            </v-card-text>
            <v-card-subtitle class="headline"
              >{{ chargeController }} A
              <v-tooltip bottom max-width="30vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-4">
                    <v-icon light> mdi-help </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("help-a") }}</span>
              </v-tooltip>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <p class="body-1 text-justify mx-auto max-width my-8">
        {{ $t("setup") }} {{ $t("voltage-regulator") }}
      </p>
      <v-card class="py-4 px-4 mx-auto my-8 max-width">
        <v-img
          :src="require('@/assets/setup.png')"
          alt="panel"
          class="shrink mr-2 mt-4"
          contain
          transition="scale-transition"
          max-height="500"
        />
      </v-card>
      <p class="body-1 text-justify mx-auto max-width my-8">
        {{ $t("wiring") }}
        <a
          href="https://1579028l.ha.azioncdn.net/img/2020/06/blog/3669/tabela-aplicacao-bitola-voltagem-amperagem-bitola-alimetacao-som-automotivo-projeto-som-metros-x-amperagem.jpg"
          target="_blank"
          >{{ $t("more-details") }}</a
        >.
      </p>
      <v-data-table
        :headers="headers"
        :items="tableItems"
        hide-default-footer
        class="elevation-1 mx-auto max-width my-8"
      ></v-data-table>
      <v-card
        class="py-4 px-4 mx-auto my-8 max-width"
        color="grey darken-3"
        elevation="8"
      >
        <v-card-title>{{ $t("devices") }}</v-card-title>
        <v-row justify="start">
          <v-col
            class="text-center"
            v-for="(device, key) in addedDevices"
            :key="key"
            cols="6"
            sm="3"
            md="2"
          >
            <div class="selected-device">
              <v-img
                :alt="device.name"
                :src="device.icon"
                class="shrink mr-2"
                contain
                transition="scale-transition"
                width="100"
              />
            </div>
            <div>
              <div class="py-2">{{ device.model }}</div>
              <div>
                <v-icon color="secondary" class="pr-2"
                  >mdi-weather-lightning</v-icon
                >
                <span
                  >{{
                    Math.round(
                      device.powerSupply.volts * device.powerSupply.amps * 10
                    ) / 10
                  }}
                  Wh</span
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="py-4 px-4 max-width mx-auto" elevation="8">
        <v-card-title>{{ $t("configurations") }}</v-card-title>
        <v-row>
          <v-col xs="12" md="6">
            <v-simple-table class="my-4 border-right">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{ $t("autonomy") }}</td>
                    <td>{{ autonomy }} {{ $t("hours-per-day") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("suntime") }}</td>
                    <td>{{ sunTime }} {{ $t("hours-per-day") }}</td>
                  </tr>
                  <tr v-if="currLocation">
                    <td>{{ $t("longitude") }}</td>
                    <td>{{ currLocation[0] }}</td>
                  </tr>
                  <tr v-if="currLocation">
                    <td>{{ $t("latitude") }}</td>
                    <td>{{ currLocation[1] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col xs="12" md="6">
            <v-simple-table class="my-4 border-left">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{ $t("total-hour-consume") }}</td>
                    <td>{{ Math.round(deviceTotalConsume * 10) / 10 }} Wh</td>
                  </tr>
                  <tr>
                    <td>{{ $t("discharge-model") }}</td>
                    <td>{{ dischargeModel }} %</td>
                  </tr>
                  <tr>
                    <td>{{ $t("loss") }}</td>
                    <td>{{ loss }}%</td>
                  </tr>
                  <tr>
                    <td>{{ $t("selected-battery-type") }}</td>
                    <td>{{ selectedBatteryBankOutput }} V</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card>
      <v-row justify="center" align="center" class="pt-12 pb-12">
        <v-col md="4" xs="12" style="text-align: center">
          <v-btn width="300" color="primary" @click="print">{{
            $t("save-pdf")
          }}</v-btn>
        </v-col>
        <v-col md="4" xs="12" style="text-align: center">
          <v-btn width="300" color="pink" @click="toggleDialog">{{
            $t("share")
          }}</v-btn>
        </v-col>
      </v-row>
      <ShareDialog
        :dialog="shareDialog"
        :url="shareUrl"
        @close="toggleDialog"
      />
    </v-main>
    <ResultPrint
      :addedDevices="addedDevices"
      :deviceTotalConsume="deviceTotalConsume"
      :sunTime="sunTime"
      :maxLoad="maxLoad"
      :dischargeModel="dischargeModel"
      :loss="loss"
      :autonomy="autonomy"
      :selectedBatteryBankOutput="selectedBatteryBankOutput"
      :panelPower="panelPower"
      :commercialBatteryAh="commercialBatteryAh"
      :batteriesInSeries="batteriesInSeries"
      :chargeController="chargeController"
      :currLocation="currLocation"
      :energeticAutonomy="energeticAutonomy"
      class="d-none d-print-block"
    />
  </div>
</template>
<script>
import ResultPrint from "@/components/ResultPrint.vue";
import ShareDialog from "@/components/dialogs/Share.vue";
import posthog from "posthog-js";

export default {
  name: "Results",
  components: {
    ShareDialog,
    ResultPrint
  },
  props: {
    addedDevices: Array,
    deviceTotalConsume: Number,
    sunTime: Number,
    dischargeModel: Number,
    loss: Number,
    autonomy: Number,
    selectedBatteryBankOutput: Number,
    maxLoad: Number,
    currLocation: Array
  },
  data() {
    return {
      shareDialog: false,
      comercialPanels: [
        5,
        10,
        30,
        60,
        80,
        100,
        100,
        120,
        160,
        180,
        200,
        220,
        250,
        300,
        320,
        330,
        355,
        365,
        370,
        375,
        395,
        400
      ],
      comercialBatteries: [6, 10, 20, 40, 60, 80, 100, 120, 160, 200, 220, 240],
      comercialChargeControllers: [10, 20, 30, 40, 50, 60, 100],
      shareUrl: window.location.href,
      headers: [
        {
          text: `${this.$t("max-current")}`,
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: `1 ${this.$t("meter")}`, value: "one" },
        { text: "1-2m", value: "onetwo" },
        { text: "2-3m", value: "twothree" },
        { text: "3-5m", value: "threefive" },
        { text: "5-7m", value: "fiveseven" },
        { text: "7-10m", value: "seventen" }
      ],
      tableItems: [
        {
          name: "1-20A",
          one: "4mm²",
          onetwo: "4mm²",
          twothree: "4mm²",
          threefive: "4mm²",
          fiveseven: "6mm²",
          seventen: "6mm²"
        },
        {
          name: "20-30A",
          one: "4mm²",
          onetwo: "4mm²",
          twothree: "6mm²",
          threefive: "6mm²",
          fiveseven: "10mm²",
          seventen: "16mm²"
        },
        {
          name: "30-40A",
          one: "4mm²",
          onetwo: "4mm²",
          twothree: "6mm²",
          threefive: "10mm²",
          fiveseven: "16mm²",
          seventen: "16mm²"
        },
        {
          name: "40-60A",
          one: "6mm²",
          onetwo: "6mm²",
          twothree: "10mm²",
          threefive: "16mm²",
          fiveseven: "16mm²",
          seventen: "21mm²"
        },
        {
          name: "60-100A",
          one: "10mm²",
          onetwo: "16mm²",
          twothree: "16mm²",
          threefive: "21mm²",
          fiveseven: "21mm²",
          seventen: "35mm²"
        }
      ]
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    energeticAutonomy() {
      return this.autonomy * this.deviceTotalConsume;
      // 24h * 24W
    },
    batteryWh() {
      return (
        this.energeticAutonomy +
        (100 / this.dischargeModel) * this.energeticAutonomy +
        (this.loss / 100) * this.energeticAutonomy
        // 24h * (100 / 50%) * 24h * (10% / 100) * 24h
      );
    },
    batteriesInSeries() {
      return this.selectedBatteryBankOutput / 12;
    },
    batteryBankCapacity() {
      return this.batteryWh / 12;
    },
    minimumBatteries() {
      const largestBattery = this.comercialBatteries[
        this.comercialBatteries.length - 1
      ];
      let found = 0;
      if (this.batteryBankCapacity > largestBattery) {
        for (let batteryNum = 2; found < 2; batteryNum++) {
          this.comercialBatteries.forEach(batteryBankCapacity => {
            if (
              batteryBankCapacity * batteryNum - this.batteryBankCapacity >
              0
            ) {
              found = batteryNum;
            }
          });
        }
      }
      return found;
    },
    commercialBatteryAh() {
      const largestBattery = this.comercialBatteries[
        this.comercialBatteries.length - 1
      ];
      if (this.batteryBankCapacity > largestBattery) {
        return this.comercialBatteries.filter(
          batteryBankCapacity =>
            batteryBankCapacity * this.minimumBatteries -
              this.batteryBankCapacity >
            0
        )[0];
      } else if (this.batteriesInSeries > 0) {
        return (
          this.comercialBatteries.filter(
            comBatteryBankCapacity =>
              comBatteryBankCapacity - this.batteryBankCapacity > 0
          )[0] / this.batteriesInSeries
        );
      } else {
        return this.comercialBatteries.filter(
          comBatteryBankCapacity =>
            comBatteryBankCapacity - this.batteryBankCapacity > 0
        )[0];
      }
    },
    numberOfBatteries() {
      return this.minimumBatteries || this.batteriesInSeries;
    },
    panelPower() {
      const round = Math.round(
        (this.energeticAutonomy + 0.3 * this.energeticAutonomy) / this.sunTime
      );
      const comercials = this.comercialPanels.filter(p => p - round > 0);
      if (comercials[0]) return comercials[0];
      else return round;
    },
    chargeController() {
      const round =
        Math.round(
          (this.deviceTotalConsume / this.selectedBatteryBankOutput) * 10
        ) / 10;
      const comercials = this.comercialChargeControllers.filter(
        c => c - round > 0
      );
      if (comercials[0]) return comercials[0];
      else return round;
    }
  },
  methods: {
    copy() {
      this.toggleDialog();
    },
    print() {
      if (window) window.print();
    },
    toggleDialog() {
      this.shareUrl = window.location.href;
      this.shareDialog = !this.shareDialog;
    }
  },
  mounted() {
    posthog.capture("Solar result", {
      addedDevices: this.addedDevices,
      deviceTotalConsume: this.deviceTotalConsume,
      sunTime: this.sunTime,
      maxLoad: this.maxLoad,
      dischargeModel: this.dischargeModel,
      loss: this.loss,
      autonomy: this.autonomy,
      selectedBatteryBankOutput: this.selectedBatteryBankOutput,
      panelPower: this.panelPower,
      commercialBatteryAh: this.commercialBatteryAh,
      batteriesInSeries: this.batteriesInSeries,
      chargeController: this.chargeController,
      currLocation: this.currLocation,
      energeticAutonomy: this.energeticAutonomy
    });
  }
};
</script>
<style scoped>
.container {
  /* max-width: 968px; */
  min-height: calc(100vh - 280px);
  margin: 0 auto;
}
.selected-device {
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.max-width {
  max-width: 968px;
}
</style>
