<template>
  <div>
    <v-container v-show="welcome" class="welcome py-8">
      <h1 class="display-2">{{ $t("welcome") }}</h1>
      <p class="py-12 headline">
        {{ $t("welcome-message") }}
      </p>
      <v-row class="mt-8">
        <v-col cols="6">
          <v-btn
            target="_blank"
            href="https://www.coolab.org/2021/01/calculadora-solar-v1/"
            color="secondary"
            >{{ $t("blog-link") }}</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-btn color="primary" @click="gotoCalc">{{ $t("start") }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <transition name="slide-fade">
      <SolarCalculator :class="[]" v-show="!welcome" />
    </transition>
  </div>
</template>
<script>
// @ is an alias to /src
import SolarCalculator from "@/components/SolarCalculator.vue";

export default {
  name: "Welcome",
  components: {
    SolarCalculator
  },
  props: {
    start: Boolean
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString).toString();
    if (urlParams.length > 0) this.gotoCalc();
  },
  computed: {
    welcome: {
      get() {
        return this.start;
      },
      set() {
        this.$emit("gotoStart");
      }
    }
  },
  methods: {
    gotoCalc() {
      this.$emit("gotoCalc");
    }
  }
};
</script>
<style scoped>
.welcome {
  max-width: 968px;
  text-align: center;
  margin: 0 auto;
  min-height: calc(100vh - 2.3 * 56px);
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100vh);
  opacity: 0;
}
</style>
