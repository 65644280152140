<template>
  <v-app class="app">
    <v-app-bar class="d-print-none" app color="primary" dark>
      <div class="d-flex align-center pointer" @click="welcome = true">
        <span v-if="!$vuetify.breakpoint.xs" class="mr-2 title">{{
          $t("solar-calculator")
        }}</span>
        <v-img :src="require('@/assets/loading.gif')" max-width="45px"></v-img>
      </div>

      <v-spacer></v-spacer>
      <v-btn color="white" @click="toggleLangDialog" fab small class="mr-4">
        <gb-flag :code="selectedLang.flag" size="small" />
      </v-btn>
      <v-btn href="https://coolab.org" target="_blank" text>
        <v-img
          v-if="!$vuetify.breakpoint.xs"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="100"
        />
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <div></div>
    <v-main class="main">
      <Welcome
        :start="welcome"
        @gotoStart="welcome = true"
        @gotoCalc="welcome = false"
      />
    </v-main>
    <v-footer class="text-center text-uppercase black--text" color="secondary">
      <v-col class="text-center caption" cols="12">
        <span>{{ $t("done-with") }}</span>
        <span class="px-2">❤️</span>
        <span>{{ $t("by") }}</span>
        <a
          style="text-decoration: none; color: #000"
          class="px-2"
          href="https://coolab.org"
          target="_blank"
          >Coolab</a
        >
        <span>- {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-footer>
    <LanguageDialog
      :selectedLang="selectedLang"
      :langs="langs"
      :dialog="showLangDialog"
      @changeLanguage="changeLanguage"
      @close="toggleLangDialog"
    />
  </v-app>
</template>

<script>
import Welcome from "@/views/Welcome.vue";
import LanguageDialog from "@/components/dialogs/Language.vue";
import posthog from "posthog-js";

const langStorageKey = "@Coolab/idioma";

export default {
  name: "App",
  components: {
    Welcome,
    LanguageDialog
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  data: () => ({
    welcome: true,
    showLangDialog: false,
    selectedLang: {
      name: "Português",
      locale: "pt",
      flag: "br"
    }
  }),
  mounted() {
    if (navigator) {
      const browserLanguage = navigator.language;
      const savedLanguage = window.localStorage.getItem(langStorageKey);
      const defaultLanguage = savedLanguage || browserLanguage.split("-")[0];
      this.changeLanguage(defaultLanguage);
      posthog.capture("Solar calculator initiated", {
        language: defaultLanguage,
        browserLanguage
      });
    }
  },
  computed: {
    langs() {
      const supportedLanguages = ["pt", "es", "en", "de"];
      return supportedLanguages.map(i => this.getLanguageData(i));
    }
  },
  methods: {
    toggleLangDialog() {
      this.showLangDialog = !this.showLangDialog;
    },
    changeLanguage(lang) {
      window.localStorage.setItem(langStorageKey, lang);
      this.$i18n.locale = lang;
      this.selectedLang = this.getLanguageData(lang);
    },
    getLanguageData(lang) {
      switch (lang) {
        case "en":
          return {
            locale: lang,
            name: "English",
            flag: "us"
          };
        case "es":
          return {
            locale: lang,
            name: "Español",
            flag: "mx"
          };
        case "de":
          return {
            locale: lang,
            name: "German",
            flag: "de"
          };
        case "pt":
          return {
            locale: lang,
            name: "Português",
            flag: "br"
          };
        default:
          return {
            locale: lang,
            name: "Português",
            flag: "br"
          };
      }
    }
  }
};
</script>
<style scoped>
.main {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("~@/assets/fundo.jpg");
  background-size: cover;
}
.pointer {
  cursor: pointer;
}
</style>
