import slugify from "slugify";

export function generateId(brand, model) {
  return `${slugify(brand.toLowerCase())}--${slugify(model.toLowerCase())}`;
}

export function parseId(devices, id) {
  const [model, brand] = id.split("--");
  const match = devices.filter(device => {
    const slugModel = slugify(device.model.toLowerCase());
    const sugBrand = slugify(device.brand.toLowerCase());
    return slugModel === model && sugBrand === brand;
  });
  const flat = match.flat(3)[0];
  return flat;
}
