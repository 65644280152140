<template>
  <v-main class="container" justify="center">
    <p>{{ $t("autonomy-help") }}</p>
    <v-row justify="center" class="py-8">
      <v-col :cols="8">
        <v-text-field
          min="1"
          v-model="autonomyData"
          :label="$t('autonomy-hours')"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="px-8 py-12">
      <v-card-text>
        {{ $t("advanced-settings") }}
      </v-card-text>
      <v-row justify="center" class="py-8">
        <v-col :cols="12" :sm="4">
          <v-text-field
            max="100"
            min="0"
            :label="$t('battery-discharge-model')"
            v-model="dischargeData"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="4">
          <v-text-field
            max="100"
            min="0"
            :label="$t('loss-%')"
            v-model="lossData"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="4">
          <v-select
            v-model="selectedBatteryBankOutputData"
            :items="batteryTypes"
            :label="$t('battery-bank-volts')"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-card>
  </v-main>
</template>
<script>
export default {
  name: "Battery",
  components: {},
  props: {
    dischargeModel: Number,
    loss: Number,
    autonomy: Number,
    selectedBatteryBankOutput: Number,
    batteryTypes: Array,
    deviceTotalConsume: Number,
    sunTime: Number,
    maxLoad: Number
  },
  computed: {
    dischargeData: {
      set(e) {
        const num = parseInt(e);
        return this.$emit("updateDischarge", num);
      },
      get() {
        return this.dischargeModel;
      }
    },
    lossData: {
      set(e) {
        const num = parseInt(e);
        return this.$emit("updateLoss", num);
      },
      get() {
        return this.loss;
      }
    },
    autonomyData: {
      set(e) {
        const num = parseInt(e);
        return this.$emit("updateAutonomy", num);
      },
      get() {
        return this.autonomy;
      }
    },
    selectedBatteryBankOutputData: {
      set(e) {
        const num = parseInt(e);
        return this.$emit("updateBatteryType", num);
      },
      get() {
        return this.selectedBatteryBankOutput;
      }
    }
  }
};
</script>
<style scoped>
.container {
  max-width: 968px;
  min-height: calc(100vh - 280px);
  margin: 0 auto;
  text-align: center;
}
.text-box {
  text-align: left;
  margin: 0 auto;
}
</style>
