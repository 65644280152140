<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      scrollable
      max-width="500px"
      persistent
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>{{ $t("custom-power-title") }}</v-card-title>
        <v-card-subtitle>{{ $t("custom-power-message") }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="pt-8" style="height: 300px;">
          <v-text-field
            :label="$t('device-volts')"
            v-model="volts"
            type="number"
            autofocus
            @focus="log"
          ></v-text-field>
          <v-text-field
            :label="$t('device-amps')"
            v-model="amps"
            type="number"
            @focus="log"
          ></v-text-field>
          <v-text-field
            :label="$t('device-name')"
            v-model="deviceName"
            @focus="log"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary darken-1" text @click="$emit('close')">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="secondary darken-1" text @click="submit">{{
            $t("add")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean
  },
  data() {
    return {
      volts: 12,
      amps: 1,
      deviceName: ""
    };
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    },
    deviceInfo() {
      return {
        type: "other",
        model: this.deviceName,
        powerSupply: {
          volts: parseFloat(this.volts),
          amps: parseFloat(this.amps)
        }
      };
    }
  },
  methods: {
    log(e) {
      console.log("FOCUSED", e);
    },
    reset() {
      this.volts = 12;
      this.amps = 1;
      this.deviceName = "";
    },
    submit() {
      this.$emit("add", this.deviceInfo);
      this.reset();
    }
  }
};
</script>
