<template>
  <v-main class="container pb-4" justify="center">
    <p class="text-box text-center pb-4">
      <!-- {{
        currLocation.length > 0
          ? $t("enter-location")
          : $t("enter-location-manually")
      }} -->
      {{ $t("enter-irradiation") }}
    </p>
    <!-- TODO: adicionar mapa e Solcast -->
    <!-- <v-chip class="location" :color="locatedColor">
      <v-icon class="pr-2">{{
        currLocation.length > 0 ? "mdi-target" : "mdi-cancel"
      }}</v-icon>
      {{ currLocation.length > 0 ? $t("located") : $t("not-located") }}
    </v-chip> -->
    <!-- <Map
      v-show="!manual"
      :location="currLocation"
      :sunTime="sunTime"
      @updateLocation="updateLocation"
      height="400px"
    /> -->
    <!-- <v-row>
      <v-col xs="12">
        <v-btn
          color="secondary"
          class="text-center"
          @click="manual = !manual"
          >{{ $t(manual ? "find-location" : "manually-add") }}</v-btn
        >
      </v-col>
    </v-row> -->
    <v-row v-show="true">
      <v-col cols="12" :sm="6">
        <v-text-field
          max="24"
          min="1"
          :label="$t('radiation-time')"
          v-model="data"
          :value="sunTime"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12">
        <v-btn
          color="secondary"
          class="text-center"
          :href="`${publicPath}Atlas_Solarimetrico_do_Brasil_2000_Horas.pdf`"
          target="_blank"
          >{{ $t("solar-atlas") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import getSolCast from "@/lib/getSolCast.js";
// import Map from "@/components/Map.vue";

export default {
  name: "Radiation",
  components: {
    // Map
  },
  props: {
    deviceTotalConsume: Number,
    sunTime: Number,
    currLocation: Array
  },
  data() {
    return {
      locationText: "Moinho, Alto Paraíso, Goiás, Brasil",
      manual: false,
      publicPath: process.env.BASE_URL
    };
  },
  async mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (pos, err) => {
        if (err) console.log("ERROR!!!");
        const { longitude, latitude } = pos.coords;
        this.$emit("updateLocation", [longitude, latitude]);
        // getSolCast(longitude, latitude);
      });
    } else {
      console.log("Geolocation not supported");
    }
  },
  computed: {
    data: {
      set(e) {
        const num = parseInt(e);
        return this.$emit("updateSunTime", num);
      },
      get() {
        return this.sunTime;
      }
    },
    locatedColor() {
      return this.currLocation.length > 0 ? "green" : "red";
    }
  },
  methods: {
    updateLocation(coords) {
      this.$emit("updateLocation", coords);
      getSolCast(coords[1], coords[0]);
    }
  }
};
</script>
<style scoped>
.container {
  max-width: 968px;
  min-height: calc(100vh - 280px);
  margin: 0 auto;
  text-align: center;
}
.text-box {
  text-align: left;
  margin: 0 auto;
}
.location {
  position: relative;
  bottom: 10px;
  /* left: 35vw; */
}
</style>
