import posthog from "posthog-js";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/mapbox-gl.css";
import VueFlags from "@growthbunker/vueflags";

Vue.use(VueFlags, {
  iconPath: "/flags/"
});

posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
  api_host: process.env.VUE_APP_POSTHOG_API
});
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
