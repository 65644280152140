<template>
  <div>
    <!-- <h1 class="title text-uppercase text-center pb-12">
      {{ $t("solar-system") }}
    </h1> -->
    <h2>{{ $t("system") }}</h2>
    <v-simple-table light class="my-4">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="title">{{ $t("panel") }}</td>
            <td class="title">{{ panelPower }} Wp</td>
          </tr>
          <tr>
            <td class="title">{{ $t("battery") }}</td>
            <td class="title">
              <span v-if="batteriesInSeries > 1">
                {{ batteriesInSeries }}
                <span class="caption"> x</span>
              </span>
              <span> {{ commercialBatteryAh }}Ah </span>
            </td>
          </tr>
          <tr>
            <td class="title">{{ $t("charge-controller") }}</td>
            <td class="title">{{ chargeController }} A</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <hr />
    <h2>{{ $t("devices") }}</h2>
    <v-simple-table light class="my-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("brand") }}
            </th>
            <th class="text-left">
              {{ $t("model") }}
            </th>
            <th class="text-left">
              {{ $t("total-use") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(device, key) in addedDevices" :key="key">
            <td>{{ device.brand }}</td>
            <td>{{ device.model }}</td>
            <td>
              {{
                Math.round(
                  device.powerSupply.volts * device.powerSupply.amps * 10
                ) / 10
              }}
              Wh
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="font-weight-black">{{ deviceTotalConsume }} Wh</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <hr />
    <h2>{{ $t("configurations") }}</h2>
    <v-row>
      <v-col cols="6">
        <v-simple-table light class="my-4 border-right">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ $t("autonomy") }}</td>
                <td>{{ autonomy }} {{ $t("hours-per-day") }}</td>
              </tr>
              <tr>
                <td>{{ $t("suntime") }}</td>
                <td>{{ sunTime }} {{ $t("hours-per-day") }}</td>
              </tr>
              <tr v-if="currLocation">
                <td>{{ $t("longitude") }}</td>
                <td>{{ currLocation[0] }}</td>
              </tr>
              <tr v-if="currLocation">
                <td>{{ $t("latitude") }}</td>
                <td>{{ currLocation[1] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <v-simple-table light class="my-4 border-left">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ $t("total-daily-consume") }}</td>
                <td>{{ deviceTotalConsume * 24 }} {{ $t("watts-per-day") }}</td>
              </tr>
              <tr>
                <td>{{ $t("discharge-model") }}</td>
                <td>{{ dischargeModel }} %</td>
              </tr>
              <tr>
                <td>{{ $t("loss") }}</td>
                <td>{{ loss }}%</td>
              </tr>
              <tr>
                <td>{{ $t("selected-battery-type") }}</td>
                <td>{{ selectedBatteryBankOutput }} V</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Results",
  props: {
    addedDevices: Array,
    deviceTotalConsume: Number,
    sunTime: Number,
    dischargeModel: Number,
    loss: Number,
    autonomy: Number,
    selectedBatteryBankOutput: Number,
    maxLoad: Number,
    panelPower: Number,
    commercialBatteryAh: Number,
    batteriesInSeries: Number,
    chargeController: Number,
    currLocation: Array
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    today() {
      const today = new Date();
      const date = today.toJSON().slice(0, 10);
      return (
        date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4)
      );
    }
  }
};
</script>
<style scoped>
hr {
  margin: 20px 0;
}
.border-right {
  border: 0.5px solid black;
  padding: 15px;
}
.border-left {
  border: 0.5px solid black;
  padding: 15px;
}
</style>
