<template>
  <v-main class="container">
    <v-row class="py-4" justify="space-around">
      <v-col
        align-items="center"
        v-for="device in deviceOptions"
        :key="device.name"
        cols="12"
        sm="3"
        @click="toggleDialog(device.name)"
      >
        <v-row justify="center" class="pb-12">
          <v-btn
            color="secondary"
            fab
            :min-width="isMobile ? 40 : 150"
            :min-height="isMobile ? 40 : 150"
            class="mb-4"
          >
            <v-img
              :src="device.icon"
              :alt="device.name"
              class="shrink mr-1 mt-4"
              contain
              transition="scale-transition"
              :width="isMobile ? 40 : 100"
              :max-height="isMobile ? 40 : 100"
            />
          </v-btn>
          <v-btn block :outlined="!isMobile">{{ $t(device.name) }}</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="!hasSelected" class="subtitle text-center pt-4 divider">
      {{ $t("select-device") }}
    </div>
    <div v-else class="my-4">
      <h2>Selecionados</h2>
      <hr />
    </div>
    <v-row>
      <v-col
        class="text-center"
        v-for="(device, key) in addedDevices"
        :key="key"
        cols="6"
        sm="3"
        md="2"
      >
        <div class="selected-device">
          <v-btn
            fab
            color="primary"
            max-width="35px"
            max-height="35px"
            class="close"
            fixed
            @click="$emit('removeDevice', device.id)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-img
            :alt="device.name"
            :src="device.icon"
            class="shrink mr-2 mt-n8"
            contain
            transition="scale-transition"
            width="100"
          />
        </div>
        <div v-if="!isMobile">
          <div class="py-2">{{ device.model }}</div>
          <div>
            <v-icon color="secondary" class="pr-2"
              >mdi-weather-lightning</v-icon
            >
            <span
              >{{
                Math.round(
                  device.powerSupply.volts * device.powerSupply.amps * 10
                ) / 10
              }}
              Wh</span
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <div v-if="hasSelected" class="title py-4 px-4 my-12 total-use">
          {{ $t("total") }}: {{ deviceTotalConsume }} Wh
          <v-tooltip bottom max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-4">
                <v-icon light> mdi-help </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("help-wh") }}</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <DeviceDialog
      list
      v-show="devicesData && dialog"
      :type="dialog || ''"
      :devicesData="
        devicesData && dialog ? devicesData.filter(i => i.type === dialog) : []
      "
      :dialog="dialog ? true : false"
      @close="toggleDialog"
      @add="add"
    />
    <OtherDialog
      :dialog="dialog === 'other' || dialog === 'add-device'"
      @close="toggleDialog"
      @add="add"
    />
  </v-main>
</template>
<script>
import DeviceDialog from "@/components/dialogs/Device.vue";
import OtherDialog from "@/components/dialogs/Other.vue";

export default {
  name: "Devices",
  components: {
    DeviceDialog,
    OtherDialog
  },
  props: {
    deviceTotalConsume: Number,
    addedDevices: Array,
    hasSelected: Boolean,
    deviceOptions: Array,
    devicesData: Array
  },
  data() {
    return {
      dialog: null
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    add(device) {
      this.toggleDialog();
      this.$emit("addDevice", device);
    },
    toggleDialog(dialog) {
      if (this.dialog === null) this.dialog = dialog;
      else this.dialog = null;
    }
  }
};
</script>
<style scoped>
.container {
  max-width: 968px;
  min-height: calc(100vh - 280px);
}
.selected-device {
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
h3 {
  min-width: 200px;
  text-align: center;
  margin: 0 auto;
  padding-top: 8px;
}
.close {
  position: relative;
  top: -30px;
  left: -45px;
}
.divider {
  text-transform: uppercase;
}
.total-use {
  width: 300px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 35px;
  text-align: center;
}
</style>
